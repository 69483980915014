import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Grid, Card, CardContent, CardActions, Button } from '@mui/material';

function Dashboard() {
  const { id } = useParams();
  const navigate = useNavigate();

  const handleCardClick = (appName) => {
    navigate(`/tenant-processes/${id}/${appName}`);
  };

  return (
    <Container>
      <Typography variant="h4" paragraph>
        Dashboard for Tenant: {id}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Lifecycle Workflow Manager
              </Typography>
              <Typography color="textSecondary">
                Manage the lifecycle workflows for this tenant.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => handleCardClick('lifecycle-workflow-manager')}>
                Open App
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Vehicle Order Form
              </Typography>
              <Typography color="textSecondary">
                Order a new vehicle to be added to the fleet.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => handleCardClick('vehicle-order-form')}>
                Open App
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Platform One
              </Typography>
              <Typography color="textSecondary">
                Platform One Embed Tester
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => handleCardClick('platform-one')}>
                Open App
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Intelligence
              </Typography>
              <Typography color="textSecondary">
                Intelligence dashboard for this tenant.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small" onClick={() => handleCardClick('intelligence')}>
                Open App
              </Button>
            </CardActions>
          </Card>
        </Grid>
        {/* Add more cards for other apps here in the future */}
      </Grid>
    </Container >
  );
}

export default Dashboard;
