import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';

// Pages
import LoginPage from './pages/Login/Login.jsx';
import Home from './pages/Home/Home.jsx';
import WorkflowManager from './pages/LifecycleWorkflow/App.jsx';
import Settings from './pages/Settings/Settings.jsx';
import TenantProcesses from './pages/TenantProcesses/TenantProcesses.jsx';
import Dashboard from './pages/TenantProcesses/[id]/Dashboard/Dashboard.jsx';
import LogsPage from './pages/Logs/LogsPage.jsx';
import VehicleOrderForm from './pages/Mock/VehicleOrderForm/VehicleOrderForm.jsx';
import Intelligence from './pages/Intelligence/IntelligencePage.jsx';
import IntelligenceDashboard from './pages/Intelligence/IntelligenceDashboardPage.jsx';
import PlatformOneEmbed from './pages/PlatformOneEmbed/PlatformOneEmbed.jsx';

// Components
import Header from './components/Header/Header.jsx';
import Sidebar from './components/Sidebar/Sidebar.jsx';
import Footer from './components/Footer/Footer.jsx';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs.jsx';
import PrivateRoute from './components/PrivateRoute/PrivateRoute.jsx';

// Themes
import '@fontsource/roboto';
import mainTheme from './styles/theme.js';

// Contexts
import { AuthProvider, useAuth } from './contexts/AuthContext/AuthContext.jsx';

// Hooks
import useIsEmbedded from './hooks/useIsEmbedded.js';

function AppContent() {

  const isEmbedded = useIsEmbedded();
  const { isAuthenticated } = useAuth();

  return (
    <div style={{ display: 'flex', height: '100vh', width: '100hw' }}>
      {isAuthenticated && (
        <>
          {!isEmbedded && <Sidebar />}
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {!isEmbedded && <Header />}
            {!isEmbedded && <Breadcrumbs />}
            <div style={{ flex: 1 }}>
              <Routes>
                <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
                <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
                <Route path="/tenant-processes" element={<PrivateRoute><TenantProcesses /></PrivateRoute>} />
                <Route path="/tenant-processes/:id" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path="/tenant-processes/:id/lifecycle-workflow-manager" element={<PrivateRoute><WorkflowManager /></PrivateRoute>} />
                <Route path="/tenant-processes/:id/vehicle-order-form" element={<PrivateRoute><VehicleOrderForm /></PrivateRoute>} />
                <Route path="/tenant-processes/:id/intelligence" element={<PrivateRoute><Intelligence /></PrivateRoute>} />
                <Route path="/tenant-processes/:id/intelligence/:season/:stage/dashboard" element={<PrivateRoute><IntelligenceDashboard /></PrivateRoute>} />
                <Route path="/tenant-processes/:id/platform-one" element={<PrivateRoute><PlatformOneEmbed /></PrivateRoute>} />
                <Route path="/logs" element={<PrivateRoute><LogsPage /></PrivateRoute>} />
              </Routes>
            </div>
            {!isEmbedded && <Footer />}
          </div>
        </>
      )}

      {!isAuthenticated && !isEmbedded && (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<LoginPage />} />
        </Routes>
      )}

    </div>
  );
}

function App() {
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <Router>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
