import React from 'react';
import { Grid, TextField } from '@mui/material';

function KeyDatesForm({ keyDates, setKeyDates }) {

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setKeyDates(prevDates => ({
      ...prevDates,
      [name]: value,
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Registration Date"
          name="registration_date"
          type="date"
          value={keyDates.registration_date || ''}
          onChange={handleInputChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Registration Expiration Date"
          name="registration_expiration_date"
          type="date"
          value={keyDates.registration_expiration_date || ''}
          onChange={handleInputChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Legal Inspection Date"
          name="legal_inspection_date"
          type="date"
          value={keyDates.legal_inspection_date || ''}
          onChange={handleInputChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Legal Inspection Expiration Date"
          name="legal_inspection_expiration_date"
          type="date"
          value={keyDates.legal_inspection_expiration_date || ''}
          onChange={handleInputChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Emissions Permit Date"
          name="emissions_permit_date"
          type="date"
          value={keyDates.emissions_permit_date || ''}
          onChange={handleInputChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Emissions Permit Expiration Date"
          name="emissions_permit_expiration_date"
          type="date"
          value={keyDates.emissions_permit_expiration_date || ''}
          onChange={handleInputChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Expected Delivery Date"
          name="expected_delivery_date"
          type="date"
          value={keyDates.expected_delivery_date || ''}
          onChange={handleInputChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>

      {/* Add more date fields as needed based on the schema */}
    </Grid>
  );
}

export default KeyDatesForm;
