import React from 'react';
import { Container, Typography, Button, Grid, Divider, Paper } from '@mui/material';

function ReviewAndSubmit({ vehicleDetails, specifications, upfits, supplierDetails, keyDates, handleSubmit }) {

  const sectionStyle = {
    padding: '16px',
    marginBottom: '24px',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9'
  };

  return (
    <Container>
      <Typography variant="h5" paragraph>
        Review Your Vehicle Order
      </Typography>

      <Paper style={sectionStyle}>
        <Typography variant="h6" gutterBottom>
          Vehicle Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>Make: {vehicleDetails.make}</Grid>
          <Grid item xs={12} sm={6}>Model: {vehicleDetails.model}</Grid>
          <Grid item xs={12} sm={6}>Trim: {vehicleDetails.trim}</Grid>
          <Grid item xs={12} sm={6}>Model Year: {vehicleDetails.model_year}</Grid>
          <Grid item xs={12} sm={6}>Color: {vehicleDetails.color}</Grid>
        </Grid>
      </Paper>

      <Divider style={{ marginBottom: '24px' }} />

      <Paper style={sectionStyle}>
        <Typography variant="h6" gutterBottom>
          Specifications
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>Engine Type: {specifications.engine_type}</Grid>
          <Grid item xs={12} sm={6}>Transmission Type: {specifications.transmission_type}</Grid>
          <Grid item xs={12} sm={6}>Drive Type: {specifications.drive_type}</Grid>
          <Grid item xs={12} sm={6}>Fuel Type: {specifications.fuel_type}</Grid>
          <Grid item xs={12} sm={6}>Towing Capacity: {specifications.towing_capacity}</Grid>
          <Grid item xs={12} sm={6}>GVWR: {specifications.gvwr}</Grid>
        </Grid>
      </Paper>

      <Divider style={{ marginBottom: '24px' }} />

      <Paper style={sectionStyle}>
        <Typography variant="h6" gutterBottom>
          Upfits
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>Tow Bar: {upfits.tow_bar ? 'Yes' : 'No'}</Grid>
          <Grid item xs={12} sm={6}>Roof Rack: {upfits.roof_rack ? 'Yes' : 'No'}</Grid>
          <Grid item xs={12} sm={6}>Bluetooth Car Kit: {upfits.bluetooth_car_kit ? 'Yes' : 'No'}</Grid>
          <Grid item xs={12} sm={6}>Navigation System: {upfits.navigation_system ? 'Yes' : 'No'}</Grid>
          <Grid item xs={12} sm={6}>Snow Chains: {upfits.snow_chains ? 'Yes' : 'No'}</Grid>
          <Grid item xs={12} sm={6}>Ski Box: {upfits.ski_box ? 'Yes' : 'No'}</Grid>
        </Grid>
      </Paper>

      <Divider style={{ marginBottom: '24px' }} />

      <Paper style={sectionStyle}>
        <Typography variant="h6" gutterBottom>
          Supplier Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>Name: {supplierDetails.name}</Grid>
          <Grid item xs={12} sm={6}>Contact Name: {supplierDetails.contact_first_name} {supplierDetails.contact_last_name}</Grid>
          <Grid item xs={12} sm={6}>Email: {supplierDetails.contact_email}</Grid>
          <Grid item xs={12} sm={6}>Phone Number: {supplierDetails.contact_phone_number}</Grid>
          <Grid item xs={12} sm={6}>Company: {supplierDetails.company_name}</Grid>
          <Grid item xs={12} sm={6}>Address: {supplierDetails.address}, {supplierDetails.city}, {supplierDetails.state} {supplierDetails.postal_code}, {supplierDetails.country}</Grid>
        </Grid>
      </Paper>

      <Divider style={{ marginBottom: '24px' }} />

      <Paper style={sectionStyle}>
        <Typography variant="h6" gutterBottom>
          Key Dates
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>Registration Date: {keyDates.registration_date}</Grid>
          <Grid item xs={12} sm={6}>Registration Expiration Date: {keyDates.registration_expiration_date}</Grid>
          <Grid item xs={12} sm={6}>Legal Inspection Date: {keyDates.legal_inspection_date}</Grid>
          <Grid item xs={12} sm={6}>Legal Inspection Expiration Date: {keyDates.legal_inspection_expiration_date}</Grid>
          <Grid item xs={12} sm={6}>Emissions Permit Date: {keyDates.emissions_permit_date}</Grid>
          <Grid item xs={12} sm={6}>Emissions Permit Expiration Date: {keyDates.emissions_permit_expiration_date}</Grid>
          <Grid item xs={12} sm={6}>Expected Delivery Date: {keyDates.expected_delivery_date}</Grid>
        </Grid>
      </Paper>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ marginTop: '24px' }}
      >
        Submit Order
      </Button>
    </Container>
  );
}

export default ReviewAndSubmit;
