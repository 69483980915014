import React from 'react';
import { Grid, TextField, MenuItem } from '@mui/material';

const engineTypes = ['Gasoline', 'Diesel', 'Electric', 'Hybrid'];
const transmissionTypes = ['Automatic', 'Manual', 'CVT', 'Semi-Automatic'];
const driveTypes = ['FWD', 'RWD', 'AWD', '4WD'];
const fuelTypes = ['Gasoline', 'Diesel', 'Electric', 'Hybrid'];

function SpecificationsForm({ specifications, setSpecifications }) {

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSpecifications(prevSpecs => ({
      ...prevSpecs,
      [name]: value,
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <TextField
          select
          fullWidth
          label="Engine Type"
          name="engine_type"
          value={specifications.engine_type || ''}
          onChange={handleInputChange}
          variant="outlined"
        >
          {engineTypes.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          select
          fullWidth
          label="Transmission Type"
          name="transmission_type"
          value={specifications.transmission_type || ''}
          onChange={handleInputChange}
          variant="outlined"
        >
          {transmissionTypes.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          select
          fullWidth
          label="Drive Type"
          name="drive_type"
          value={specifications.drive_type || ''}
          onChange={handleInputChange}
          variant="outlined"
        >
          {driveTypes.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          select
          fullWidth
          label="Fuel Type"
          name="fuel_type"
          value={specifications.fuel_type || ''}
          onChange={handleInputChange}
          variant="outlined"
        >
          {fuelTypes.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Towing Capacity"
          name="towing_capacity"
          value={specifications.towing_capacity || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Gross Vehicle Weight Rating"
          name="gvwr"
          value={specifications.gvwr || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      {/* Add more fields as needed based on the schema */}
    </Grid>
  );
}

export default SpecificationsForm;
