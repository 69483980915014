import React from 'react';
import { Grid, TextField, MenuItem } from '@mui/material';

const makes = ['Ford', 'Chevrolet', 'Toyota']; // Example makes, you can replace with real data
const models = {
  Ford: ['F-150', 'Mustang'],
  Chevrolet: ['Silverado', 'Camaro'],
  Toyota: ['Corolla', 'Tacoma']
}; // Example models per make, you can replace with real data
const trims = ['Base', 'Sport', 'Luxury']; // Example trims

function VehicleDetailsForm({ vehicleDetails, setVehicleDetails }) {

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVehicleDetails(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <TextField
          select
          fullWidth
          label="Make"
          name="make"
          value={vehicleDetails.make || ''}
          onChange={handleInputChange}
          variant="outlined"
        >
          {makes.map((make) => (
            <MenuItem key={make} value={make}>
              {make}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          select
          fullWidth
          label="Model"
          name="model"
          value={vehicleDetails.model || ''}
          onChange={handleInputChange}
          variant="outlined"
          disabled={!vehicleDetails.make}
        >
          {(models[vehicleDetails.make] || []).map((model) => (
            <MenuItem key={model} value={model}>
              {model}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          select
          fullWidth
          label="Trim"
          name="trim"
          value={vehicleDetails.trim || ''}
          onChange={handleInputChange}
          variant="outlined"
          disabled={!vehicleDetails.model}
        >
          {trims.map((trim) => (
            <MenuItem key={trim} value={trim}>
              {trim}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Model Year"
          name="model_year"
          value={vehicleDetails.model_year || ''}
          onChange={handleInputChange}
          variant="outlined"
          type="number"
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Color"
          name="color"
          value={vehicleDetails.color || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
}

export default VehicleDetailsForm;
