import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, CardActions, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getDataSources } from '../../services/dataSourcesAPI';

function TenantProcesses() {
  const [dataSources, setDataSources] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDataSources();
  }, []);

  const fetchDataSources = async () => {
    const sources = await getDataSources();
    setDataSources(sources);
  };

  const handleCardClick = (source) => {
    // Navigate to the dashboard with the selected source's ID
    navigate(`/tenant-processes/${source.id}`);
  };

  return (
    <Container>
      <Typography variant="h4" paragraph>Tenant Processes</Typography>
      <Grid container spacing={3}>
        {dataSources.map((source) => (
          <Grid item xs={12} sm={6} md={4} key={source.id}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="div">
                  {source.name}
                </Typography>
                <Typography color="textSecondary">
                  {source.api_url}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => handleCardClick(source)}>
                  View Dashboard
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default TenantProcesses;
