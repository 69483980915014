import axios from 'axios';

export const getDataSources = async () => {
  const tokenDetails = localStorage.getItem('tokenDetails');
  const response = await axios.get('/api/v1/data-source/', {
    headers: {
      Authorization: `JWT ${tokenDetails}`,
    },
  });
  return response.data;
};

export const addDataSource = async (dataSource) => {
  const tokenDetails = localStorage.getItem('tokenDetails');
  await axios.post('/api/v1/data-source/', dataSource, {
    headers: {
      Authorization: `JWT ${tokenDetails}`,
    },
  });
};

export const updateDataSource = async (dataSource) => {
  const tokenDetails = localStorage.getItem('tokenDetails');
  await axios.patch(`/api/v1/data-source/${dataSource.id}/`, dataSource, {
    headers: {
      Authorization: `JWT ${tokenDetails}`,
    },
  });
};
