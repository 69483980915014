import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, Typography, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { addDataSource, updateDataSource } from '../../services/dataSourcesAPI';

function DataSourceForm({ editingDataSource, onSave, onCancel }) {
  const [formState, setFormState] = useState({
    id: '',
    name: '',
    api_url: '',
    username: '',
    password: '',
    pagination_map: {
      next: '',
      results: ''
    }
  });

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (editingDataSource) {
      setFormState(editingDataSource);
    } else {
      setFormState({
        id: '',
        name: '',
        api_url: '',
        username: '',
        password: '',
        pagination_map: {
          next: '',
          results: ''
        }
      });
    }
  }, [editingDataSource]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => {
      if (name.startsWith('pagination_map')) {
        const key = name.split('.')[1];
        return {
          ...prevState,
          pagination_map: {
            ...prevState.pagination_map,
            [key]: value,
          },
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editingDataSource) {
      await updateDataSource(formState);
    } else {
      await addDataSource(formState);
    }
    onSave();
    onCancel(); // Hide the form after saving
  };

  const handleTestConnection = async () => {
    // Implement your logic for testing the connection here
    alert('Test connection functionality not yet implemented.');
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container>
      <Box sx={{ mb: 2, p: 2, border: '1px solid #4caf50', borderRadius: 2 }}>
        <Typography variant="h6">{editingDataSource ? 'Edit Data Source' : 'Add Data Source'}</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            name="id"
            label="Id"
            value={formState.id}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="name"
            label="Name"
            value={formState.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="api_url"
            label="API URL"
            value={formState.api_url}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="username"
            label="Username"
            value={formState.username}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            value={formState.password}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Typography variant="subtitle1" sx={{ mt: 2 }}>Pagination Map</Typography>
          <TextField
            name="pagination_map.next"
            label="Next Key"
            value={formState.pagination_map.next}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            name="pagination_map.results"
            label="Results Key"
            value={formState.pagination_map.results}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button type="submit" variant="contained" color="primary">
              {editingDataSource ? 'Update Data Source' : 'Add Data Source'}
            </Button>
            <Button
              variant="outlined"
              sx={{ color: 'red', borderColor: 'pink' }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleTestConnection}>
              Test Connection
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
}

export default DataSourceForm;
