import React, { useState } from 'react';
import { Container, Button, Typography, Divider } from '@mui/material';
import VehicleDetailsForm from './../../../components/VehicleDetailsForm/VehicleDetailsForm';
import SpecificationsForm from '../../../components/SpecificationsForm/SpecificationsForm';
import UpfitsForm from './../../../components/UpfitsForm/UpfitsForm';
import SupplierDetailsForm from './../../../components/SupplierDetailsForm/SupplierDetailsForm';
import KeyDatesForm from './../../../components/KeyDatesForm/KeyDatesForm';
import ReviewAndSubmit from './../../../components/ReviewAndSubmitForm/ReviewAndSubmitForm';

function VehicleOrderForm() {
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [specifications, setSpecifications] = useState({});
  const [upfits, setUpfits] = useState({});
  const [supplierDetails, setSupplierDetails] = useState({});
  const [keyDates, setKeyDates] = useState({});

  const handleSubmit = () => {
    const payload = {
      xid: "generated-xid",
      status: "CD_FEE_PAID",
      status_updated_at: new Date().toISOString(),
      service_id: 1234,  // Example service ID
      vin: "vin-placeholder",
      order: {
        xid: "generated-order-xid",
        additional_details: {},
        status: "ORDER_PLACED",
        expected_delivery_date: "2024-09-01",
        net_price: 0,
        supplier: supplierDetails
      },
      dates: keyDates,
      license_plate: {},
      specifications: {
        ...vehicleDetails,
        ...specifications,
        accessories: upfits
      }
    };

    console.log('Payload:', payload);
    // Add API call here to send payload
  };

  return (
    <Container>
      <Typography variant="h4" paragraph>
        Vehicle Order Form
      </Typography>

      <Divider style={{ marginBottom: '24px' }} />

      <Typography variant="h5" gutterBottom>
        Vehicle Details
      </Typography>
      <VehicleDetailsForm
        vehicleDetails={vehicleDetails}
        setVehicleDetails={setVehicleDetails}
      />

      <Divider style={{ margin: '24px 0' }} />

      <Typography variant="h5" gutterBottom>
        Specifications
      </Typography>
      <SpecificationsForm
        specifications={specifications}
        setSpecifications={setSpecifications}
      />

      <Divider style={{ margin: '24px 0' }} />

      <Typography variant="h5" gutterBottom>
        Upfits / Modifications
      </Typography>
      <UpfitsForm
        upfits={upfits}
        setUpfits={setUpfits}
      />

      <Divider style={{ margin: '24px 0' }} />

      <Typography variant="h5" gutterBottom>
        Supplier Details
      </Typography>
      <SupplierDetailsForm
        supplierDetails={supplierDetails}
        setSupplierDetails={setSupplierDetails}
      />

      <Divider style={{ margin: '24px 0' }} />

      <Typography variant="h5" gutterBottom>
        Key Dates
      </Typography>
      <KeyDatesForm
        keyDates={keyDates}
        setKeyDates={setKeyDates}
      />

      <Divider style={{ margin: '24px 0' }} />

      <ReviewAndSubmit
        handleSubmit={handleSubmit}
        vehicleDetails={vehicleDetails}
        specifications={specifications}
        upfits={upfits}
        supplierDetails={supplierDetails}
        keyDates={keyDates}
      />
    </Container>
  );
}

export default VehicleOrderForm;
