import React from 'react';
import { Grid, Checkbox, FormControlLabel, Typography } from '@mui/material';

function UpfitsForm({ upfits, setUpfits }) {

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setUpfits(prevUpfits => ({
      ...prevUpfits,
      [name]: checked,
    }));
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Vehicle Upfits / Modifications
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={upfits.tow_bar || false}
                onChange={handleCheckboxChange}
                name="tow_bar"
                color="primary"
              />
            }
            label="Tow Bar"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={upfits.roof_rack || false}
                onChange={handleCheckboxChange}
                name="roof_rack"
                color="primary"
              />
            }
            label="Roof Rack"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={upfits.bluetooth_car_kit || false}
                onChange={handleCheckboxChange}
                name="bluetooth_car_kit"
                color="primary"
              />
            }
            label="Bluetooth Car Kit"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={upfits.navigation_system || false}
                onChange={handleCheckboxChange}
                name="navigation_system"
                color="primary"
              />
            }
            label="Navigation System"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={upfits.snow_chains || false}
                onChange={handleCheckboxChange}
                name="snow_chains"
                color="primary"
              />
            }
            label="Snow Chains"
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={upfits.ski_box || false}
                onChange={handleCheckboxChange}
                name="ski_box"
                color="primary"
              />
            }
            label="Ski Box"
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default UpfitsForm;
