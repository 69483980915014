import React from 'react';
import { Grid, TextField } from '@mui/material';

function SupplierDetailsForm({ supplierDetails, setSupplierDetails }) {

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSupplierDetails(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Supplier Name"
          name="name"
          value={supplierDetails.name || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Contact First Name"
          name="contact_first_name"
          value={supplierDetails.contact_first_name || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Contact Last Name"
          name="contact_last_name"
          value={supplierDetails.contact_last_name || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Contact Email"
          name="contact_email"
          value={supplierDetails.contact_email || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Contact Phone Number"
          name="contact_phone_number"
          value={supplierDetails.contact_phone_number || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Company Name"
          name="company_name"
          value={supplierDetails.company_name || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Address"
          name="address"
          value={supplierDetails.address || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="City"
          name="city"
          value={supplierDetails.city || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="State"
          name="state"
          value={supplierDetails.state || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="Postal Code"
          name="postal_code"
          value={supplierDetails.postal_code || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Country"
          name="country"
          value={supplierDetails.country || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          label="Additional Details"
          name="details"
          value={supplierDetails.details || ''}
          onChange={handleInputChange}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
}

export default SupplierDetailsForm;
