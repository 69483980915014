import React, { createContext, useContext, useEffect, useState } from 'react';
import Axios from 'axios';

// Create the Auth context
const AuthContext = createContext();

// Custom hook to use the Auth context
export const useAuth = (options = {}) => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('tokenDetails'));

  const checkToken = async (token) => {
    try {
      const response = await Axios.get('/api/v1/health/validate-token', {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });
      return response.status === 200;
    } catch (error) {
      console.error('Token validation failed', error);
      return false;
    }
  }



  const login = async (token) => {
    const isValid = await checkToken(token);
    if (isValid) {
      setIsAuthenticated(true);
      setToken(token);
      localStorage.setItem('tokenDetails', token);
    } else {
      setIsAuthenticated(false);
      setToken(null);
      localStorage.removeItem('tokenDetails');
    }
  };

  const logout = () => {
    localStorage.removeItem('tokenDetails');
    setToken(null);
    setIsAuthenticated(false);
  };

  const handleMessage = (event) => {
    // If the origin isn't from a ridecell domain, ignore the message
    const ridecellRegex = /^https?:\/\/([a-zA-Z0-9-]+\.)?ridecell\.(com|io)$/;
    if (event.data.type !== 'SUITOOL_INIT' && !ridecellRegex.test(event.origin)) {
      return;
    }
    localStorage.setItem('tokenDetails', event.data.authToken);
    localStorage.setItem('tenantId', event.data.tenantId);
    login(event.data.authToken);
  }


  useEffect(() => {

    async function processToken(token) {
      if (token) {
        const isValid = await checkToken(token);
        if (isValid) {
          setIsAuthenticated(true);
          setToken(token);
          localStorage.setItem('tokenDetails', token);
        } else {
          setIsAuthenticated(false);
          localStorage.removeItem('tokenDetails');
        }
      } else {
        setIsAuthenticated(false);
        setToken(null);
        localStorage.removeItem('tokenDetails');
      }
    }

    const currentToken = token;

    if (currentToken) {
      processToken(currentToken);
    }

  }, [token]);

  useEffect(() => {
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])


  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, token }}>
      {children}
    </AuthContext.Provider>
  );
};
