import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Box } from '@mui/material';
import { Link } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Dataset from '@mui/icons-material/Dataset';
import DatasetLinked from '@mui/icons-material/DatasetLinked';
import SchemaIcon from '@mui/icons-material/Schema';
import LogoDevIcon from '@mui/icons-material/LogoDev';

import { useAuth } from '../../contexts/AuthContext/AuthContext.jsx';

const drawerWidth = 280; // Adjust this value to make the sidebar wider

function Sidebar({ className }) {

  const { logout } = useAuth();

  return (
    <div className={className}>

      <Drawer
        variant="permanent"
        anchor="left"
        sx={{ width: drawerWidth, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' } }}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <List>
            <ListItem component={Link} to="/">
              <ListItemIcon><HomeIcon /></ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem component={Link} to="/tenant-processes">
              <ListItemIcon><Dataset /></ListItemIcon>
              <ListItemText primary="Tenant Processes" />
            </ListItem>
            <ListItem component={Link} to="/multi-tenant-processes">
              <ListItemIcon><DatasetLinked /></ListItemIcon>
              <ListItemText primary="Multi-Tenant Processes" />
            </ListItem>
            <ListItem component={Link} to="/logs">
              <ListItemIcon><LogoDevIcon /></ListItemIcon>
              <ListItemText primary="Logs" />
            </ListItem>
          </List>
          <Box flexGrow={1} />
          <Divider />
          <List>
            <ListItem component={Link} to="/settings">
              <ListItemIcon><SettingsIcon /></ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
            <ListItem component={Link} onClick={logout}>
              <ListItemIcon><ExitToAppIcon /></ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </div>
  );
}

export default Sidebar;
