import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';

import Axios from 'axios';

// Components
import WorkflowManager from './Components/WorkflowManager.js';
import Sidebar from './Components/Sidebar.js';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator.jsx';

// Hooks
import useIsEmbedded from '../../hooks/useIsEmbedded.js';
import useLifecycleDataFetch from '../../hooks/useLifecycleDataFetch.js';


// Themes
import './App.css';

function App() {
  const { id } = useParams();
  const isEmbedded = useIsEmbedded();
  const resolvedURL = useMemo(() => (!id || isEmbedded) ? '/api/v1/tenant/assets/config/lifecycle' : `/api/v1/tenant/${id}/assets/config/lifecycle`);
  const { isLoading, data } = useLifecycleDataFetch({ url: resolvedURL });
  return (
    <div className="app-container">
      <LoadingIndicator isLoading={isLoading} />
      {!isEmbedded && <Sidebar className="sidebar" />}
      <ReactFlowProvider>
        <WorkflowManager lifecycleData={data} />
      </ReactFlowProvider>
    </div>
  );
}

export default App;
