import React, { useState, useEffect } from 'react';
import { Container, Typography, Button } from '@mui/material';
import DataSourceList from './DataSourceList';
import DataSourceForm from './DataSourceForm';
import { getDataSources } from '../../services/dataSourcesAPI';

function Settings() {
  const [dataSources, setDataSources] = useState([]);
  const [editingDataSource, setEditingDataSource] = useState(null);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    fetchDataSources();
  }, []);

  const fetchDataSources = async () => {
    const sources = await getDataSources();
    setDataSources(sources);
  };

  const handleAddDataSource = () => {
    setEditingDataSource(null);
    setShowForm(true);
  };

  const handleEditDataSource = (source) => {
    setEditingDataSource(source);
    setShowForm(true);
  };

  const handleCancel = () => {
    setShowForm(false);
    setEditingDataSource(null);
  };

  return (
    <Container>
      <Typography variant="h4" paragraph>Settings</Typography>
      <Button variant="contained" color="primary" onClick={handleAddDataSource}>
        Add Data Source
      </Button>
      <DataSourceList dataSources={dataSources} onEdit={handleEditDataSource} />
      {showForm && (
        <DataSourceForm
          editingDataSource={editingDataSource}
          onSave={fetchDataSources}
          onCancel={handleCancel}
        />
      )}
    </Container>
  );
}

export default Settings;
