import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';

function Header({ className }) {
  return (
    <div className={className}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h5" color={'white'}>Solutions Utility Interface Tool</Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
