import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { getLogs } from '../../services/logsAPI'; // Ensure this matches your actual path

const LOG_METHODS = ['GET', 'POST', 'PUT', 'DELETE']; // Example methods

function LogsPage() {
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedMethod, setSelectedMethod] = useState('');

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = async () => {
    const logsData = await getLogs(); // Fetch logs from your backend
    console.log({
      logsData,
    })
    const logsArray = [...Object.values(logsData.salesforce ?? []), ...Object.values(logsData.ams ?? [])];
    console.log({
      logsArray,
    })
    setLogs(logsArray);
    setFilteredLogs(logsArray); // Initialize with all logs
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterLogs(e.target.value, selectedMethod);
  };

  const handleMethodChange = (e) => {
    setSelectedMethod(e.target.value);
    filterLogs(searchTerm, e.target.value);
  };

  const filterLogs = (term, method) => {
    let filtered = logs;

    if (term) {
      filtered = filtered.filter(log =>
        log.url.includes(term) || log.method.includes(term)
      );
    }

    if (method) {
      filtered = filtered.filter(log => log.method === method);
    }

    setFilteredLogs(filtered);
  };

  return (
    <Container>
      <Typography variant="h4" paragraph>
        Logs
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ marginRight: '8px' }}
        />
        <TextField
          label="Method"
          variant="outlined"
          fullWidth
          select
          value={selectedMethod}
          onChange={handleMethodChange}
        >
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          {LOG_METHODS.map((method) => (
            <MenuItem key={method} value={method}>
              {method}
            </MenuItem>
          ))}
        </TextField>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Method</TableCell>
              <TableCell>Path</TableCell>
              <TableCell align="right">Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLogs.map((log, index) => (
              <TableRow key={index}>
                <TableCell>{log.method}</TableCell>
                <TableCell>{log.path}</TableCell>
                <TableCell align="right">{new Date(log.timestamp).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default LogsPage;
